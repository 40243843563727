import '@babel/polyfill';
import React, { Component } from 'react';
import ErrorBoundary from './errorBoundaries/errorBoundary';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  Outlet,
  Navigate
} from "react-router-dom";
import './App.css';
import './chart.css';
import '@progress/kendo-theme-material/dist/all.css';
import { FormattedMessage } from 'react-intl';
import Layout from './general/Layout';
import DashboardWraper from './general/Dashboard';
import Stations from './stations/Stations';
import Settings from './general/Settings';
import Profile from './general/Profile';
import Alerts from './alerts/Alerts';
// import Modal from './Modal';
import Tab from './general/Tab';
import Login from './general/Login';
import { getAuthToken, BASEURI } from './utils';
import TabDialog from './general/TabDialog';
import Modal from './general/Modal';
import Admin from './admin/Admin';
import DGAUploadJobs from './dga/DGAUploadJobs';
import ConfirmDeleteDialog from './general/ConfirmDeleteDialog';
import Tools from './tools/Tools';
import Billing from './billing/Billing';
import IrrigationScheduler from './scheduler/Scheduler';
import ScoutPage from './scout/ScoutPage';
import NotFound from './routes/NotFound';
import PrivateRoutes from './routes/PrivateRoutes';
import AgScoutPrivacyPolicy from './privacyPolicy/AgScoutPrivacyPolicy';
import AgViewerPrivacyPolicy from './privacyPolicy/AgViewerPrivacyPolicy';
import IrrigationEvents from './scheduler/IrrigationEvents';
import IrrigationReport from './scheduler/summary/IrrigationReport';
import TabLayout from './scheduler/TabLayout';


class LoginCtn extends Component {

  render() {
    return (
      <div>
        <Outlet />
      </div>);
  }
}


class LayoutCtn extends Component {

  render() {
    return <div> {this.props.children} </div>;
  }
}


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updateComponent: false,
      loggingIn: false,
      notInitial: false,
      stations: [],
      allStations: [],
      markers: [],
      markersCopy: [],
      weatherVariables: [],
      loadingWeatherVariables: false,
      loadingMarkers: false,
      loading: false,
      page: 'admin',
      navigateTo: null,
      tabs: [],
      loggedin: localStorage.getItem('token') ? true : false,
      newTab: {},
      tabDialogAction: 'new',
      showTabDialog: false,
      errorMsg: "",
      userName: null,
      showDeleteDialog: false,
      isAdmin: false,
      isOrganization: false,
      isLockedUser: false,
      editWidgets: true,
      pageRefreshed: true,
    }

    this.addTab = this.addTab.bind(this);
    this.setNavigateTo = this.setNavigateTo.bind(this);
    this.setPageRefreshed = this.setPageRefreshed.bind(this);
    this.loadTabs = this.loadTabs.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleTabFormChange = this.handleTabFormChange.bind(this);
    this.openTabDialog = this.openTabDialog.bind(this);
    this.closeTabDialog = this.closeTabDialog.bind(this);
    this.deleteTab = this.deleteTab.bind(this);
    this.loadStations = this.loadStations.bind(this);
    this.handleMarkersFilterChange = this.handleMarkersFilterChange.bind(this);
    this.loadWeatherVariables = this.loadWeatherVariables.bind(this);
    this.loadMarkers = this.loadMarkers.bind(this);
    this.setStations = this.setStations.bind(this);
    this.loadUserData = this.loadUserData.bind(this);
  }

  loadUserData() {

    this.loadMarkers();
    this.loadWeatherVariables();
    this.loadStations();
    this.loadTabs();

    let url = BASEURI + '/api/dashboard/superuser/',
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({ loggedin: false });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((client) => {
            this.setState({
              userName: !client.name ? client.user.username : client.name,
              isAdmin: client.user.is_superuser,
              isOrganization: client.is_organization,
              isLockedUser: client.locked,
              editWidgets: client.edit_widgets
            })
          })
          .catch(error => {
            console.error('Error:', error.code);
          });
      });

  }

  componentDidMount() {

    setTimeout(() => { this.setState({ notInitial: true }) }, 1000);

    this.loadUserData()

  }

  componentDidUpdate() {
    if (this.state.updateComponent) {
      this.loadUserData();
      this.setState({ updateComponent: false })
    }
    window.componentHandler.upgradeDom();

  }

  setStations(stations) {
    this.setState({ stations: stations });
  }


  loadStations() {
    if (this.state.notInitial) {
      this.setState({ loading: true });
    }
    let url = `${BASEURI}/api/stations/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((stations) => {
            if (!this.state.notInitial) {
              this.setState({ notInitial: true });
            }
            this.setState({
              loading: false,
              stations: stations,
              allStations: stations,
            });
          })
          .catch(error => console.error('Error:', error));

      });
  }

  loadMarkers() {
    this.setState({ loadingMarkers: true });
    let url = BASEURI + `/api/dashboard/marker/primary/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({
            loggedin: false
          });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((markers) => {
            if (Array.isArray(markers))
              this.setState({
                markers: markers,
                markersCopy: markers,
                loadingMarkers: false,
              })
          })
          .catch(error => {
            console.error('Error:', error.code);
            this.setState({ loadingMarkers: false });
          });
      });

  }

  loadWeatherVariables() {
    this.setState({ loadingWeatherVariables: true });
    let url = BASEURI + `/api/dashboard/weathervariable/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({
            loggedin: false
          });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((weatherVariables) => {
            if (weatherVariables)
              this.setState({
                weatherVariables: weatherVariables,
                loadingWeatherVariables: false,
              });
          })
          .catch(error => {
            console.error('Error:', error.code);
            this.setState({
              loadingWeatherVariables: false,
            })
          });
      });
  }

  handleMarkersFilterChange(event) {

    let filterText = event.filter.value.toLowerCase();
    let filtered = this.state.markersCopy.slice().filter(item => {
      let domain = `${item.device && item.device.name + ' ' + item.device.serial} ${item.location_name}`
        .toLowerCase();

      if (domain.indexOf(filterText) !== -1)
        return true;
      else
        return false;
    });

    this.setState({
      markers: filtered
    });

  }

  login(credentials) {
    this.setState({ loggingIn: true });

    const url = BASEURI + '/api/token/';
    fetch(url,
      {
        method: 'POST',
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    )
      .then(response => response.json())
      .then((token) => {
        if (token.refresh) {

          localStorage.setItem('token', JSON.stringify(token));


          //what can be done here ?
          window.location.reload();

          // update is used to load material design lite cdns and scripts
          // this.setState({ loggedin: true, updateComponent: true });


          // this.forceUpdate();
        } else {
          // alert("Invalid Credentails")
          this.setState({
            loggingIn: false,
            errorMsg: <FormattedMessage
              id='app.login.error'
              defaultMessage="Invalid username or password."
              description='User login error'
            />
          })
        }


      })
      .catch(error => {
        this.setState({ loggingIn: false });
        console.error('Error:', error)
      });
    // .finally(()=>{
    //   this.forceUpdate();
    // });

  }



  loadTabs() {

    var token = localStorage.getItem('token');
    if (!token)
      this.setState({ loggedin: false });

    let url = BASEURI + '/api/tabs/',
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({ loggedin: false });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((tabs) => {
            if (Array.isArray(tabs)) {
              this.setState({
                tabs: tabs,
                loggedin: true,
              });
            }
          })
          .catch(error => {
            console.error('Error:', error.code);
          });

      });

  }


  addTab(e) {
    e.preventDefault();

    let url = this.state.tabDialogAction === 'edit'
      ? `${BASEURI}/api/tab/rud/${this.state.newTab.id}/`
      : `${BASEURI}/api/tabs/`,
      options = {
        method: this.state.tabDialogAction === 'edit' ? 'PUT' : 'POST',
        body: JSON.stringify(this.state.newTab),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((tab) => {
            let tabs = this.state.tabs.slice(),
              index;

            if (this.state.tabDialogAction === 'new')
              tabs.push(tab);
            else {
              for (let i in this.state.tabs)
                if (tab.id === this.state.tabs[i].id)
                  index = i;

              tabs.splice(index, 1, tab);
            }

            this.setState({
              tabs: tabs,
              navigateTo: "dashboard/" + tab.id
            });

          })
          .catch(error => console.log('Error:', error));

      });

  }

  openTabDialog(e, action, tabId) {
    let newTab = { id: tabId };
    if (action === "edit") {
      for (let i in this.state.tabs) {
        if (tabId === this.state.tabs[i].id)
          newTab.name = this.state.tabs[i].name;
      }

    }

    this.setState({ showTabDialog: true, tabDialogAction: action, newTab: newTab });

  }

  closeTabDialog(e, tabId) {

    this.setState({ showTabDialog: false });

  }

  deleteTab() {
    // if (!window.confirm(`Are you sure you want to delete tab '${this.state.newTab.name}' and the charts in it ?`))
    //   return;

    let url = `${BASEURI}/api/tab/rud/${this.state.newTab.id}/`,
      options = {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then(() => {
            let tabs = this.state.tabs.slice(),
              index;

            for (let i in this.state.tabs)
              if (this.state.newTab.id === this.state.tabs[i].id)
                index = i;

            tabs.splice(index, 1);

            this.setState({
              tabs: tabs,
              navigateTo: "dashboard/" + this.state.tabs[0].id,
              showTabDialog: false
            });

          })
          .catch(error => console.log('Error:', error));

      });

  }

  setNavigateTo(navigateTo) {
    this.setState({
      navigateTo: navigateTo,
    })
  }

  setPageRefreshed(value) {
    this.setState({
      pageRefreshed: value,
    })
  }

  handleTabFormChange(event) {
    let id = this.state.newTab.id;
    this.setState({ newTab: { name: event.target.value, id: id } });
  }

  logout(e) {

    localStorage.removeItem('token');
    this.setState({
      loggedin: localStorage.getItem('token') ? true : false
    });

    //reloading page to fix half loading
    window.location.reload()
  }


  render() {
    const router = createBrowserRouter(
      createRoutesFromElements(
        <Route>
          <Route element={
            <PrivateRoutes
              lockedUser={this.state.isLockedUser}
              loggedin={this.state.loggedin}
              navigateTo={this.state.navigateTo}
              setNavigateTo={this.setNavigateTo}
              pageRefreshed={this.state.pageRefreshed}
              setPageRefreshed={this.setPageRefreshed}

            />}>
            <Route path='/' element={
              <LayoutCtn key='layoutctn' >
                <Layout key={'layout'}
                  handleLocaleChange={this.props.handleLocaleChange}
                  locale={this.props.locale}
                  userName={this.state.userName}
                  isLockedUser={this.state.isLockedUser}
                  logout={this.logout}
                  tabList={this.state.tabs}
                  open={this.openTabDialog}
                  isAdmin={this.state.isAdmin}
                  isOrganization={this.state.isOrganization}
                />
              </LayoutCtn>}>
              <Route index element={<Admin
                isAdmin={this.state.isAdmin}
                isOrganization={this.state.isOrganization}
                logout={this.logout}
                key='admin'
                markers={this.state.markers}
              />} />
              <Route path='stations' element={<Stations intl={this.props.intl} key='stations'
                tabs={this.state.tabs} />} />
              <Route path='alerts' element={<Alerts intl={this.props.intl} key='alerts' />} />
              <Route path='dga' element={<DGAUploadJobs key='dga' />} />
              <Route path='tools' element={<Tools
                key='copy'
                stations={this.state.stations}
                allStations={this.state.allStations}
                isRegularUser={!(this.state.isAdmin || this.state.isOrganization)}
              />} />
              <Route path='billing' element={<Billing isAdmin={this.state.isAdmin} key='billing' locked={this.state.isLockedUser} />} />
              <Route path='schedule' element={<TabLayout key='layout' />} />


              <Route path='agscout' element={<ScoutPage />} />

              <Route path='/dashboard'>
                <Route path=':id'
                  element={
                    <ErrorBoundary>
                      <DashboardWraper
                        tabs={this.state.tabs}
                        intl={this.props.intl}
                        setStations={this.setStations}
                        loadStations={this.loadStations}
                        stations={this.state.stations}
                        allStations={this.state.allStations}
                        handleMarkersFilterChange={this.handleMarkersFilterChange}
                        loadingWeatherVariables={this.state.loadingWeatherVariables}
                        weatherVariables={this.state.weatherVariables}
                        loadingMarkers={this.state.loadingMarkers}
                        markers={this.state.markers}
                        isActive={true}
                        addTab={this.addTab}
                        handleTabFormChange={this.handleTabFormChange}
                        openTabDialog={this.openTabDialog}
                        closeTabDialog={this.closeTabDialog}
                        editWidgets={this.state.editWidgets}
                        locale={this.props.locale.localeId}
                      />
                    </ErrorBoundary>}
                >
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={<LoginCtn key='loginctn' />}>
            <Route path='/login' element={
              <Login
                key='login'
                navigateTo={this.state.navigateTo}
                errorMsg={this.state.errorMsg}
                login={this.login}
                loggedin={this.state.loggedin}
                loggingIn={this.state.loggingIn} />} />
          </Route>
          <Route path='/frontend' element={<Navigate to='/' />} />
          <Route path='privacy-policy'>
            <Route path='agscout' element={<AgScoutPrivacyPolicy />} />
            <Route path='agviewer' element={<AgViewerPrivacyPolicy />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route >
      )
    )

    return (
      <>
        <RouterProvider router={router} />
        <Modal>
          <TabDialog
            intl={this.props.intl}
            visible={this.state.showTabDialog}
            close={this.closeTabDialog}
            create={(e) => { this.addTab(e); this.closeTabDialog() }}
            delete={this.deleteTab}
            handleChange={this.handleTabFormChange}
            tab={this.state.newTab}
            action={this.state.tabDialogAction}
            showDeleteDialog={() => this.setState({ showDeleteDialog: true })}
          />
          {
            this.state.showDeleteDialog &&
            <ConfirmDeleteDialog
              message={<p> <b>{this.state.newTab.name} </b> <FormattedMessage
                id='app.chart.deleteChart.deleteMessage'
                defaultMessage="will be deleted along with all of it's data. Type {delete} below to confirm."
              /></p>}
              onClose={() => this.setState({ showDeleteDialog: false })}
              remove={() => { this.deleteTab(); this.setState({ showDeleteDialog: false }) }} />
          }
        </Modal>
      </>
    )


  }
}

export default App;
